import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "./hooks/auth";

const ProtectedRoute = ({ children }) => {
  const { isAuthed } = useAuth();
  const location = useLocation();
  if (!isAuthed()) {
    let path = `/auth/login?redirect=${encodeURIComponent(location.pathname)}`;
    return <Navigate to={path} replace />;
  }
  return children;
};

export default ProtectedRoute;