import { useEffect } from "react";
import style from "./App.module.scss";
import { Routes, Route } from "react-router-dom";
import { Provider } from 'react-redux';
import store from "./store";
import useGaTracker from './hooks/gaTracker';
import { ThemeProvider, createTheme, makeStyles, StylesProvider } from '@material-ui/core/styles';

import Home from "./components/Home/Home.lazy";
import ExamPaperList from "./components/ExamPaperList/index.lazy";
import ExamPaper from "./components/ExamPaper/index.lazy";
import Login from "./components/Login/index.lazy";
import ProtectedRoute from "./ProtectedRoute";
import { AuthProvider } from "./hooks/auth";

const theme = createTheme();
function App() {

  useGaTracker();

  return (
    <StylesProvider injectFirst>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <Routes>
              <Route path="/auth/login" element={<Login />} />

              <Route path="/" element={
                <ProtectedRoute>
                  <Home />
                </ProtectedRoute>
              } >
                <Route path="/exampaper/:examPaperId" element={<ExamPaper />} />
                <Route path="/exampaper" element={<ExamPaperList />} />
              </Route>


            </Routes>
          </AuthProvider>
        </ThemeProvider>
      </Provider>
    </StylesProvider>
  );
}

export default App;
