import _axios from "axios"

const axios = (baseURL) => {
    //建立一個自定義的axios
    const instance = _axios.create({
        baseURL: baseURL || process.env.REACT_APP_TOEIC_API_URL, //JSON-Server端口位置
        timeout: 30000,
    });

    // const clearAuth = () => {
    //     window.localStorage.removeItem('userAuth');
    //     window.location = "/";
    // }

    // const refreshAccessToken = async () => {
    //     const userAuthValue = window.localStorage.getItem('userAuth');
    //     const userAuth = JSON.parse(userAuthValue);
    //     const postData = {
    //         refreshToken: userAuth.refreshToken
    //     };
    //     try {
    //         const responsData = await instance.post("/Auth/RefreshToken", postData);
    //         window.localStorage.setItem('userAuth', JSON.stringify(responsData.data.data));
    //     } catch {
    //         clearAuth();
    //     }
    // };

    // const exclude = [
    //     "/Auth/Login",
    //     "/Auth/RefreshToken",
    //     "/MyAccount/PasswordResetApply",
    //     "/MyAccount/PasswordResetConfirm",
    //     "/UserRegister/EmailInvite"
    // ];

    // instance.interceptors.request.use(
    //     async request => {
    //         if (exclude.indexOf(request.url) === -1) {
    //             const userAuthValue = window.localStorage.getItem('userAuth');
    //             const userAuth = JSON.parse(userAuthValue);
    //             request.headers = {
    //                 'Authorization': `Bearer ${userAuth.accessToken}`,
    //                 'Accept': 'application/json'
    //             }
    //         }
    //         return request;
    //     },
    //     error => {
    //         Promise.reject(error)
    //     });

    // // Response interceptor for API calls
    // instance.interceptors.response.use((response) => {
    //     return response
    // }, async function (error) {
    //     const originalRequest = error.config;
    //     if (error.response?.status === 401) {
    //         if (error.response.data.Message === "Token Expired" && !originalRequest._retry) {
    //             originalRequest._retry = true;
    //             await refreshAccessToken();
    //             return instance(originalRequest);
    //         }
    //         clearAuth();

    //     }
    //     return Promise.reject(error);
    // });

    return instance;
};


export { axios };
export default axios();