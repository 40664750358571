import React, { useState, useEffect, useContext, createContext } from "react";
import { useDispatch } from "react-redux";
import {
    useParams, useNavigate
} from "react-router-dom";
import authApi from "../services/general/auth";
const authContext = createContext();

export function AuthProvider({ children }) {
    const auth = useAuthProvider();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
    return useContext(authContext);
};


function useAuthProvider() {
    const navigate = useNavigate();
    const login = async (username, password) => {
        await authApi.login(username, password)
            .then(function (response) {
                let userAuth = response.data.data;
                setUserAuth(userAuth);
            });
        return
    };

    const logout = () => {
        localStorage.removeItem('userAuth');
        navigate("/");
    }


    const isAuthed = () => {
        const userAuthValue = window.localStorage.getItem('userAuth');
        if (!userAuthValue)
            return false;
        const userAuth = JSON.parse(userAuthValue);
        if (!userAuth.accessToken)
            return false;
        if (userAuth.re < Date.now())
            return false;
        return true;
    }

    const setUserAuth = (userAuth) => {
        localStorage.setItem("userAuth", JSON.stringify(userAuth))
    }


    return {
        isAuthed,
        login,
        logout,
        setUserAuth
    };
}